/* eslint-disable */

import Vue from 'vue'
import "./assets/theme/common.css";
import ElementUI from 'element-ui';
import App from './App.vue'
import store from './store'
import VueStorage from 'vue-ls'
import Fragment from 'vue-fragment'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import querystring from 'querystring';
import {getCookie, setCookie} from "@/dbCommon/utils/cookie.js";
import { getToken } from '@/dbCommon/utils/auth';
import { getData } from '@/api/index';


import ReceptionShow from './page/receptionShow.vue';
import Count from './page/count.vue';
import ReceptionList from './page/receptionList.vue';
import Login from './page/users/login.vue';
import Password from './page/users/password.vue';
import Member from './page/users/member.vue';
import Title from './components/title.vue';
import vueRouter from 'vue-router';
import Dashboard from '@/layout/dashboard'
import Afboard from '@/layout/afboard'



Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(vueRouter);
Vue.use(ElementUI);
Vue.use(Fragment.Plugin)
Vue.use(VueStorage, {
  namespace: 'gzh__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})


const router = new vueRouter({
  routes: [
    {
      name: 'login',
      path: '/login',
      components: {
        default: Login,
        title: Title
      },
      meta: { title: '登录' }
    },
    {
      name: 'password',
      path: '/password',
      components: {
        default: Password,
        title: Title
      },
      meta: { title: '修改密码' }
    },
    {
      name: 'member',
      path: '/member',
      components: {
        default: Member,
        title: Title
      },
      meta: { title: '个人中心' }
    },
    {
      name: 'voice',
      path: '/voice',
      component:()=>import('@/page/users/voice'),
      meta: { title: '语音采集' }
    },

    //售前销售顾问

    {
      name: 'receptionShow',
      path: '/receptionShow',
      components: { default: ReceptionShow },
    },
    {
      name: 'receptionList',
      path: '/receptionList',
      components: {
        default: ReceptionList,
        title: Title
      },
      meta: { title: '接待列表' }
    },
    {
      name: 'driveReceptionList',
      path: '/driveReceptionList',
      component:()=>import('@/page/driveReceptionList'),
      meta: { title: '试乘试驾' }
    },
    {
      name: 'driveCount',
      path: '/driveCount',
      component:()=>import('@/page/driveCount'),
      meta: { title: '试驾分析' }
    },
    {
      name: 'count',
      path: '/count',
      components: {
        default: Count,
        title: Title
      },
      meta: { title: '统计分析' }
    },


    {
      name:"qaList",
      path:"/qaList",
      component:()=>import('@/page/qaList'),
      meta:{title:"质检反馈汇总"}
    },
    {
      name:"qaForm",
      path:"/qaForm",
      component:()=>import('@/page/qaForm'),
      meta:{title:"新增质检反馈"}
    },
    /*
    {
      path: '/',
      component:'',
      redirect:"/login",
      children:[
      ]
    },
    */

    //售前明细模块
    {
      name: 'checkDetailShow',
      path: '/checkDetailShow',
      component:()=>import('@/page/seller/checkDetailShow'),
      meta: { title: '检核明细详情' }
    },
    {
      name: 'checkDetailList',
      path: '/checkDetailList',
      component:()=>import('@/page/seller/checkDetailList'),
      meta: { title: '检核明细列表' }
    },
    {
      name: 'driveList',
      path: '/driveList',
      component:()=>import('@/page/seller/driveList'),
      meta: { title: '试驾明细列表' }
    },

    // 售前经销店
    {
      name:"dealerIndex",
      path:"/analysis/index",
      component:()=>import('@/page/analysis/index'),
      meta:{title:"统计分析"}
    },
    {
      name:"dealerStaff",
      path:"/analysis/staffList",
      component:()=>import('@/page/analysis/staffList'),
      meta:{title:"接待员工检核"}
    },

    {
      name:"driveStaff",
      path:"/analysis/driveStaff",
      component:()=>import('@/page/analysis/staffDriveList'),
      meta:{title:"试驾员工检核"}
    },
    //售前看板
    {
      name:"boardIndex",
      path:"/boardIndex",
      component:()=>import('@/page/boardIndex'),
      meta:{title:""}
    },
    {
      path: '/',
      component:Dashboard,
      redirect:"/login",
      children:[
        {
          path:"dashboard/country",
          name:"BoardCountry",
          component:()=>import('@/page/dashboard/country'),
          meta:{title:"接待全国看板"}
        },
        {
          path:"dashboard/region",
          name:"BoardRegion",
          component:()=>import('@/page/dashboard/region'),
          meta:{title:"接待大区看板"}
        },
        {
          path:"dashboard/area",
          name:"BoardArea",
          component:()=>import('@/page/dashboard/area'),
          meta:{title:"接待小区看板"}
        },
        {
          path:"dashboard/store",
          name:"BoardStore",
          component:()=>import('@/page/dashboard/store'),
          meta:{title:"接待经销商看板"}
        }
      ]
    },
    // -------售后------

    {
      name: 'afReceptionShow',
      path: '/afReceptionShow',
      component:()=>import('@/page/afseller/receptionShow'),
      meta: { title: '接待详情' }
    },
    {
      name: 'afReceptionList',
      path: '/afReceptionList',
      component:()=>import('@/page/afseller/receptionList'),
      meta: { title: '接待列表' }
    },
    {
      name: 'afCount',
      path: '/afCount',
      component:()=>import('@/page/afseller/count'),
      meta: { title: '售后分析' }
    },
    //售后明细模块
    /*
    {
      name: 'afCheckDetailShow',
      path: '/afCheckDetailShow',
      component:()=>import('@/page/afdealer/checkDetailShow'),
      meta: { title: '检核明细详情' }
    },
    */
    {
      name: 'afCheckDetailList',
      path: '/afCheckDetailList',
      component:()=>import('@/page/afdealer/checkDetailList'),
      meta: { title: '明细列表' }
    },

    // 售后经销店
    {
      name:"afDealerIndex",
      path:"/afdealer/index",
      component:()=>import('@/page/afdealer/index'),
      meta:{title:"首页"}
    },
    {
      name:"afDealerAnalysis",
      path:"/afdealer/analysis",
      component:()=>import('@/page/afdealer/analysis'),
      meta:{title:"售后分析"}
    },
    {
      name:"afDealerStaff",
      path:"/afdealer/staffList",
      component:()=>import('@/page/afdealer/staffList'),
      meta:{title:"接待员工检核"}
    },
    //售后看板
    {
      name:"afOwnerIndex",
      path:"/afowener/index",
      component:()=>import('@/page/afowener/index'),
      meta:{title:"首页"}
    },
    {
      name:"afBoardIndex",
      path:"/boardIndex",
      component:()=>import('@/page/boardIndex'),
      meta:{title:""}
    },
    {
      path: '/',
      component:Afboard,
      redirect:"/login",
      children:[
        /*
        {
          path:"afowener/countryDefault",
          name:"afCountryDefault",
          component:()=>import('@/page/afowener/countryDefault'),
          meta:{title:"接待全国看板"}
        },
        {
          path:"afowener/regionDefault",
          name:"afRegionDefault",
          component:()=>import('@/page/afowener/regionDefault'),
          meta:{title:"接待大区看板"}
        },
        {
          path:"afowener/areaDefault",
          name:"afAreaDefault",
          component:()=>import('@/page/afowener/areaDefault'),
          meta:{title:"接待小区看板"}
        },
        {
          path:"afowener/storeDefault",
          name:"afStoreDefault",
          component:()=>import('@/page/afowener/storeDefault'),
          meta:{title:"接待经销商看板"}
        },
        */
        {
          path:"afowener/country",
          name:"afCountry",
          component:()=>import('@/page/afowener/countryIndex'),
          meta:{title:"接待全国看板"}
        },
        {
          path:"afowener/region",
          name:"afRegion",
          component:()=>import('@/page/afowener/regionIndex'),
          meta:{title:"接待大区看板"}
        },
        {
          path:"afowener/area",
          name:"afArea",
          component:()=>import('@/page/afowener/areaIndex'),
          meta:{title:"接待小区看板"}
        },
        {
          path:"afowener/store",
          name:"afStore",
          component:()=>import('@/page/afowener/storeIndex'),
          meta:{title:"接待经销商看板"}
        }
      ]
    },
  ]
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.path.indexOf('dashboard') == -1) {
      const doc = window.document;
      const docEl = doc.documentElement;
      docEl.removeAttribute('data-dpr');
      docEl.removeAttribute('style');
    } else {
      flexibleFn(window, window['lib'] || (window['lib'] = {}));
      //location.reload();
    }
    const url = location.href.match(/\?(.*)$/);
    const search = url && querystring.parse(url[1]) || {};
    const authId = search.adminId;
    const hasToken = getToken();
    const authToken = getCookie('AUTH_TOKEN');

    const sameId = authId == getCookie('AUTH_ID');
    const sameToken = authToken == hasToken;

    if (authId && (!authToken || !sameId || !sameToken)) {
      await store.dispatch('Authorization', authId);
      authId && setCookie('AUTH_ID', authId);
      next();
      return;
    }

    next();
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')




function flexibleFn(win, lib) {
    var doc = win.document;
    var docEl = doc.documentElement;
    var metaEl = doc.querySelector('meta[name="viewport"]');
    var flexibleEl = doc.querySelector('meta[name="flexible"]');
    var dpr = 0;
    var scale = 0;
    var tid;
    var flexible = lib.flexible || (lib.flexible = {});

    if (metaEl) {
        console.warn('将根据已有的meta标签来设置缩放比例');
        var match = metaEl.getAttribute('content').match(/initial\-scale=([\d\.]+)/);
        if (match) {
            scale = parseFloat(match[1]);
            dpr = parseInt(1 / scale);
        }
    } else if (flexibleEl) {
        var content = flexibleEl.getAttribute('content');
        if (content) {
            var initialDpr = content.match(/initial\-dpr=([\d\.]+)/);
            var maximumDpr = content.match(/maximum\-dpr=([\d\.]+)/);
            if (initialDpr) {
                dpr = parseFloat(initialDpr[1]);
                scale = parseFloat((1 / dpr).toFixed(2));
            }
            if (maximumDpr) {
                dpr = parseFloat(maximumDpr[1]);
                scale = parseFloat((1 / dpr).toFixed(2));
            }
        }
    }

    if (!dpr && !scale) {
        var isAndroid = win.navigator.appVersion.match(/android/gi);
        var isIPhone = win.navigator.appVersion.match(/iphone/gi);
        var devicePixelRatio = win.devicePixelRatio;
        if (isIPhone) {
            // iOS下，对于2和3的屏，用2倍的方案，其余的用1倍方案
            if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
                dpr = 3;
            } else if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)){
                dpr = 2;
            } else {
                dpr = 1;
            }
        } else {
            // 其他设备下，仍旧使用1倍的方案
            dpr = 1;
        }
        scale = 1 / dpr;
    }

    docEl.setAttribute('data-dpr', dpr);
    if (!metaEl) {
        metaEl = doc.createElement('meta');
        metaEl.setAttribute('name', 'viewport');
        metaEl.setAttribute('content', 'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');
        if (docEl.firstElementChild) {
            docEl.firstElementChild.appendChild(metaEl);
        } else {
            var wrap = doc.createElement('div');
            wrap.appendChild(metaEl);
            doc.write(wrap.innerHTML);
        }
    }

    function refreshRem(){
        var width = docEl.getBoundingClientRect().width;
        if (width / dpr > 540) {
            width = 540 * dpr;
        }
        var rem = width / 10;
        docEl.style.fontSize = rem + 'px';
        flexible.rem = win.rem = rem;
    }

    win.addEventListener('resize', function() {
        clearTimeout(tid);
        tid = setTimeout(refreshRem, 300);
    }, false);
    win.addEventListener('pageshow', function(e) {
        if (e.persisted) {
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
        }
    }, false);

    if (doc.readyState === 'complete') {
        doc.body.style.fontSize = 12 * dpr + 'px';
    } else {
        doc.addEventListener('DOMContentLoaded', function(e) {
            doc.body.style.fontSize = 12 * dpr + 'px';
        }, false);
    }


    refreshRem();

    flexible.dpr = win.dpr = dpr;
    flexible.refreshRem = refreshRem;
    flexible.rem2px = function(d) {
        var val = parseFloat(d) * this.rem;
        if (typeof d === 'string' && d.match(/rem$/)) {
            val += 'px';
        }
        return val;
    }
    flexible.px2rem = function(d) {
        var val = parseFloat(d) / this.rem;
        if (typeof d === 'string' && d.match(/px$/)) {
            val += 'rem';
        }
        return val;
    }

}
