<template>
  <div class="container">
    <div class="login-box">
      <el-form ref="loginForm" :rules="rules" :model="form" class="login-form">
        <el-form-item label="">
          <div class="title">营销罗盘</div>
        </el-form-item>
        <el-form-item label="" prop="username">
          <el-input
            type="text"
            class="input"
            placeholder="用户名"
            v-model="form.username"
          >
          <i slot="prefix" class="el-input__icon user_icon"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            class="input"
             placeholder="密码"
            v-model="form.password"
          >
          <i slot="prefix" class="el-input__icon pwd_icon"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="checked" class="remind">记住密码</el-checkbox>
        </el-form-item>
        <el-button class="login-btn" @click="login">登录</el-button>
        <el-form-item label="">
          <div class="agree">
          <el-checkbox v-model="agree"><span class="text">我已阅读并同意</span></el-checkbox>
          <a href="https://api.linkprofit.cn/index.php/api/admin/protocolHtml">《用户协议》</a>和<a href="https://api.linkprofit.cn/index.php/api/admin/privacyHtml">《隐私政策》</a>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {jump} from "@/dbCommon/utils/index";
import {getCookie} from "@/dbCommon/utils/cookie.js";
import { postForm } from '@/api'

export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      rules:{
        username:[
          {required:true, message: '请输入用户名', trigger: 'blur'}
        ],
        password:[
          {required:true, message: '请输入密码', trigger: 'blur'}
        ]
      },
      checked:true,
      agree:false,
    };
  },
  methods: {
    jumpToPage(type) {

      const userInfo = Vue.ls.get('USER_INFO');
      const {login_type, gzh_sale_menu_arr, gzh_sa_menu_arr} = userInfo || this.loginData || {};
      const salePage = gzh_sale_menu_arr && gzh_sale_menu_arr.length ? gzh_sale_menu_arr[0] : 'member';
      const afPage = gzh_sa_menu_arr && gzh_sa_menu_arr.length ? gzh_sa_menu_arr[0] : 'member';
      const rolePage = {
        1: salePage,
        2: salePage,
        3: 'afOwnerIndex',
        4: afPage,
        5: afPage,
        6: 'afDealerIndex',
        7: salePage,
        8: afPage,
        9: 'afOwnerIndex',
      }
      const t = type || login_type;
      this.$router.push({name: rolePage[t]});
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.agree) {
            this.$message.info('请勾选同意后再进行登录');
            return;
          }

          const lgInfo = this.checked ? this.form : null;
          Vue.ls.set('LOGIN_INFO', lgInfo);
          this.logining = true;

          this.$store.dispatch('Login',this.form).then(res=>{
            this.logining = false;
            if (!res.status) {
              this.$message.error(res.msg);
              return;
            }
            this.loginData =  res.data || {};
            this.jumpToPage();
          }).catch(err=>{
            this.$message.error(err);
          })
        }
      });
    },
  },
  created() {
    const login = Vue.ls.get('LOGIN_INFO');
    if (login) {
      this.form = {...login};
    }

    const token = Vue.ls.get('ACCESS_TOKEN');
    const login_type = Vue.ls.get('LOGIN_TYPE');
    if (token && login_type) {
      this.jumpToPage(login_type);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: url("~@/assets/login_bg.jpg") no-repeat;
  background-size: 100%;
  height: 100%;
  .login-box {
    position: relative;
    top: 50%;
    transform: translateY(-55%;);
    width: 15.5rem;
    height: 18rem;
    background: url("~@/assets/login_border.png") no-repeat;
    background-size: contain;
    margin: 0px auto;
    padding: 0 1.2rem;
    font-weight: 400;
    .login-form {
      width: 100%;
      height: 100%;
      color: #fff;
      .agree {
        margin-top: 0.4rem;
        font-size: 0.6rem;
        color: #fff;
        white-space: nowrap;
        a {
          color: #FAC15C;
        }
        .text {
          font-size: 0.6rem;
        }
      }
      .title {
        text-align: center;
        color: #fff;
        font-size: 0.9rem;
        padding: 2rem 0 1rem 0;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0.5rem;
      }
      ::v-deep .el-form-item__content {
        line-height: 1.3;
      }
      ::v-deep .el-form-item__error {
        position: relative;
      }
      .input {
        ::v-deep .el-input__inner {
          background: transparent;
          border-radius: 1px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          color: #fff;
          font-size: 0.7rem; 
          height: 2rem;
          line-height: 2rem;
        }
        
        ::v-deep .el-input__prefix {
          padding-top: 0.6rem;
        }
      }
      ::v-deep .is-error .el-input__inner{
        border-color: #ED5A54;
      }
      ::v-deep .is-checked .el-checkbox__inner {
        background: #377EEA !important;
        border: 1px solid #377EEA;
        color: #fff;
      }
      ::v-deep .el-checkbox__inner {
        border-radius: 0;
        background: #fff !important;
        border: 1px solid #fff;
        color: #fff;
      }
      ::v-deep .el-checkbox__label {
        color: #fff;
      }
      .login-btn {
        padding: 0px;
        background: #377EEA;
        color: #fff;
        border: 0;
        border-radius: 0;
        height: 2rem;
        line-height: 2rem;
        width: 100%;
        text-align: center;
      }

    }
  }
  .user_icon{
    width: 0.8rem;
    height: 0.8rem;
    background: url("~@/assets/user.png") no-repeat;
    background-size: contain;
    display: inline-block;
  
  }
  .pwd_icon{
    width: 0.8rem;
    height: 0.8rem;
    background: url("~@/assets/pwd.png") no-repeat;
    background-size: contain;
    display: inline-block;
  }
}
</style>
